// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cms-admin-index-tsx": () => import("./../src/pages/cms/admin/index.tsx" /* webpackChunkName: "component---src-pages-cms-admin-index-tsx" */),
  "component---src-pages-cms-editor-index-tsx": () => import("./../src/pages/cms/editor/index.tsx" /* webpackChunkName: "component---src-pages-cms-editor-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-home-tsx": () => import("./../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-search-result-tsx": () => import("./../src/templates/SearchResult.tsx" /* webpackChunkName: "component---src-templates-search-result-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../src/templates/Tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

