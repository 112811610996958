import * as React from "react";
import "./src/assets/css/normalize.css";
import "./src/assets/css/font.css";
import "./src/assets/css/google-map.css";
import Layout from "./src/Layout";

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

// export const onInitialClientRender = () => {
//   if (window.location.pathname === "/") {
//     setTimeout(function () {
//       document.getElementById("___loader").style.display = "none";
//     }, 1000);
//   } else {
//     document.getElementById("___loader").style.display = "none";
//   }
// };
