import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto", "Noto Sans JP", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      light: "#5eb4f7",
      main: "#1085c4",
      dark: "#005993",
      contrastText: "#fff",
    },
    secondary: {
      light: "#4b3f3c",
      main: "#231916",
      dark: "#000000",
      contrastText: "#fff",
    },
  },
});

export default theme;
