module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://kazi-online.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"ja_jp","url":"https://kazi-online.com/","site_name":"舵オンライン │ 船遊びの情報サイト（舵社）"},"twitter":{"cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-179236321-1","head":true,"anonymize":true,"respectDNT":true,"exclude":["/cms/**"],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LW8RS56WEK"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/cms/**"],"delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"舵オンライン　│ 船遊びの情報サイト（舵社）","short_name":"Kazi Online","description":"舵オンラインは、『Kazi』、『ボート倶楽部』を刊行する舵社が運営する、船遊びに関する情報サイト。ヨット、ボート、ボート釣り、クルージング、ボート免許、カヌーなど、プレジャーボートや海に関するさまざまなニュースをお届けします。","lang":"ja","display":"standalone","icon":"src/assets/img/favicon.png","start_url":"/","background_color":"`#fff`","theme_color":"#fff","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"fff15fc6997626eff958250763e1447c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Noto Sans JP","Roboto"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false,"disableMinification":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
